import './bootstrap';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

// atlantis template dependencies
try {
   // core
   // require('./config.js');
   // window.Popper = require('@popperjs/core').default;
   // window.$ = window.jQuery = require('jquery');
   // require('bootstrap');

   // window.Swal = require('sweetalert2/dist/sweetalert2.min.js');

   // require('select2/dist/js/select2.full.min.js')

   // require ('owl.carousel');

   // require('summernote/dist/summernote-bs5.min');

   // require('./functions/_global');
   // require('./functions/_plugins');
   
} catch (e) {}
